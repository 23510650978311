import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { ReducerStateContant } from "./app/helper/constants/general-const";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Toast from "./app/components/shared/Toast";

const App = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [authorizedAccess, setAuthorizedAccess] = useState(ReducerStateContant.pending);


  useEffect(() => {
    if (isLoggedIn) {
      setAuthorizedAccess(ReducerStateContant.fulfilled)
    }
  }, [isLoggedIn]);

  return (
    <>
      {
        isLoggedIn ? <AppRoutes authorizedAccess={authorizedAccess} active={isLoggedIn} /> : <AuthRoutes />
      }
      <ToastContainer />
    </>

  );
}

export default App;
