import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { getallCustomersACTION, getCustomerACTION, insertUpdateCustomerAction, customerChangeStatusACTION, customerDeleteACTION, getallRestuarantACTION, getRestuarantACTION, getDatabaseACTION, insertUpdateRestuarantAction, restuaranatChangeStatusACTION, restuarantDeleteACTION } from "../../actions/customers/customerAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    customers: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCustomer: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    restuarant: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedRestuarant: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedDatabase: {
        data: {
            records: null,
            totalRecords: 0,
        },
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateRestuarantDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    activeInactiveRestuarantDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteRestuarantDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

};
const customerReducer = createSlice({
    name: "customer",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCustomerDetailsState(state, action) {
            state.selectedCustomer = INITIAL_STATE.selectedCustomer;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedRestuarant = INITIAL_STATE.selectedRestuarant;
            state.insertUpdateRestuarantDetails = INITIAL_STATE.insertUpdateRestuarantDetails;
        },
    },
    extraReducers: (builder) => {

        // getallCustomersACTION start

        builder.addCase(getallCustomersACTION.fulfilled, (state, action) => {
            state.customers.data = action.payload.data;
            state.customers.apiMsg.status = action.meta.requestStatus;
            state.customers.apiMsg.message = "success";
        });
        builder.addCase(getallCustomersACTION.pending, (state, action) => {
            state.customers.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallCustomersACTION.rejected, (state, action) => {
            state.customers.apiMsg.message = action.error.message;
            state.customers.apiMsg.status = action.meta.requestStatus;
        });


        // getallCustomersACTION end
        //------------------------------- getCustomerACTION start -------------------------

        builder.addCase(getCustomerACTION.fulfilled, (state, action) => {
            state.selectedCustomer.data = action.payload.data;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
            state.selectedCustomer.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getCustomerACTION.pending, (state, action) => {
            state.selectedCustomer.data = null;
            state.selectedCustomer.apiMsg.message = action.meta.requestStatus;;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getCustomerACTION.rejected, (state, action) => {
            state.selectedCustomer.apiMsg.message = action.error.message;
            state.selectedCustomer.apiMsg.status = action.meta.requestStatus;
        });


        //   getCustomerACTION end

        // insertUpdateCustomerAction start

        builder.addCase(
            insertUpdateCustomerAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.customers.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.customers.data.records = updatedRecord;
                }
                else {
                    if (state.customers.data.records.length) {
                        state.customers.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.customers.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateCustomerAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateCustomerAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateCustomerAction end

        // customerChangeStatusACTION start


        builder.addCase(customerChangeStatusACTION.fulfilled, (state, action) => {
            state.customers.data.records = state.customers.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(customerChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(customerChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // customerChangeStatusACTION end



        //------------------------------- customerDeleteACTION start -------------------------

        builder.addCase(customerDeleteACTION.fulfilled, (state, action) => {

            state.customers.data.records = state.customers.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(customerDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(customerDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- customerDeleteACTION end -------------------------

        // getallRestuarantACTION start

        builder.addCase(getallRestuarantACTION.fulfilled, (state, action) => {
            state.restuarant.data = action.payload.data;
            state.restuarant.apiMsg.status = action.meta.requestStatus;
            state.restuarant.apiMsg.message = "success";
        });
        builder.addCase(getallRestuarantACTION.pending, (state, action) => {
            state.restuarant.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getallRestuarantACTION.rejected, (state, action) => {
            state.restuarant.apiMsg.message = action.error.message;
            state.restuarant.apiMsg.status = action.meta.requestStatus;
        });

        // getallRestuarantACTION end

        //------------------------------- getRestuarantACTION start -------------------------

        builder.addCase(getRestuarantACTION.fulfilled, (state, action) => {
            state.selectedRestuarant.data = action.payload.data;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
            state.selectedRestuarant.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getRestuarantACTION.pending, (state, action) => {
            state.selectedRestuarant.data = null;
            state.selectedRestuarant.apiMsg.message = action.meta.requestStatus;;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getRestuarantACTION.rejected, (state, action) => {
            state.selectedRestuarant.apiMsg.message = action.error.message;
            state.selectedRestuarant.apiMsg.status = action.meta.requestStatus;
        });
        //   getRestuarantACTION end

        //------------------------------- getDatabaseACTION start -------------------------

        builder.addCase(getDatabaseACTION.fulfilled, (state, action) => {
            state.selectedDatabase.data.records = action.payload.data;
            state.selectedDatabase.apiMsg.status = action.meta.requestStatus;
            state.selectedDatabase.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getDatabaseACTION.pending, (state, action) => {
            state.selectedDatabase.data.records = null;
            state.selectedDatabase.apiMsg.message = action.meta.requestStatus;;
            state.selectedDatabase.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getDatabaseACTION.rejected, (state, action) => {
            state.selectedDatabase.apiMsg.message = action.error.message;
            state.selectedDatabase.apiMsg.status = action.meta.requestStatus;
        });


        //   getDatabaseACTION end

        // insertUpdateRestuarantAction start

        builder.addCase(
            insertUpdateRestuarantAction.fulfilled,
            (state, action) => {

                state.insertUpdateRestuarantDetails.data = action.payload.data.data;
                state.insertUpdateRestuarantDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateRestuarantDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.restuarant.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.restuarant.data.records = updatedRecord;
                }
                else {
                    if (state.restuarant.data.records.length) {
                        state.restuarant.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.restuarant.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );
        builder.addCase(
            insertUpdateRestuarantAction.pending,
            (state, action) => {
                state.insertUpdateRestuarantDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateRestuarantAction.rejected,
            (state, action) => {

                state.insertUpdateRestuarantDetails.apiMsg.message = action.error.message;
                state.insertUpdateRestuarantDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateRestuarantAction end

        // restuaranatChangeStatusACTION start


        builder.addCase(restuaranatChangeStatusACTION.fulfilled, (state, action) => {
            state.restuarant.data.records = state.restuarant.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restuaranatChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restuaranatChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // restuaranatChangeStatusACTION end



        //------------------------------- restuarantDeleteACTION start -------------------------

        builder.addCase(restuarantDeleteACTION.fulfilled, (state, action) => {

            state.restuarant.data.records = state.restuarant.data.records.filter((item, index) => item.id !== action.payload.data.id)
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restuarantDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restuarantDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restuarantDeleteACTION end -------------------------

    },
});

export const { RESET, ResetCustomerDetailsState } = customerReducer.actions;
export default customerReducer.reducer;